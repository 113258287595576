import { inject, Injectable } from "@angular/core";

import { first, map, shareReplay, startWith, switchMap, tap } from "rxjs/operators";
import { combineLatest, MonoTypeOperatorFunction, Observable, zip } from "rxjs";

import { INavNode, INavNodeParameter } from "@logex/framework/lg-application";

import { SharedConfigService } from "@codman/shared/util-logex-framework-setup";
import { DEFAULT_DOMAIN, RegistryTenant, TENANT_DOMAINS } from "@codman/shared/assets";
import {
    CodmanUserAuthorizationService,
    parseProductCode,
} from "@codman/shared/data-access-authorization";
import { AuthorizationExtendedPermission } from "@codman/shared/data-access-authorization-api";

import { DataAccessSharedApiService } from "./data-access-shared-api.service";
import {
    ApplicationStructure,
    APPNAME_TO_APPINSTANCE_CA_LOOKUP,
    APPNAME_TO_APPINSTANCE_LOOKUP,
    CodmanStructure,
    DescriptivesStructure,
    DescriptivesSubset,
    ExplorationStructure,
    ExplorationSubset,
    IndicatorsStructure,
    PatientsStructure,
    RegistryStructure,
} from "./data-access-shared-api.types";
import { Dashboards, organizationIdUrlParam } from "@codman/shared/types";

export const ALLOWED_APPS = [
    Dashboards.Exploration,
    Dashboards.Indicators,
    Dashboards.Descriptives,
];
const ALLOWED_APPS_WITH_OVERVIEW = [...ALLOWED_APPS, "Overview"];

@Injectable({
    providedIn: "root",
})
export class SharedNavigationService {
    private _dataAccessSharedApiService = inject(DataAccessSharedApiService);
    _authorizationService = inject(CodmanUserAuthorizationService);
    _sharedConfigService = inject(SharedConfigService);

    private _dashEnv = "-test";
    private _dotEnv = ".test";
    private _tenant?: RegistryTenant = "dica";
    private _currentApp = "";

    readonly currentOrganizationStructure$: Observable<CodmanStructure>;
    readonly structureBasedOnPermissions$: Observable<CodmanStructure>;
    readonly navigation$: Observable<INavNode[]>;
    readonly registryNavigation$: Observable<INavNode[]>;

    constructor() {
        this._sharedConfigService.configuration$.pipe(first()).subscribe(config => {
            this._dashEnv = config.dashEnv;
            this._dotEnv = config.dotEnv;
            this._tenant = config.tenant;
            this._currentApp = config.application;
        });

        this.currentOrganizationStructure$ = combineLatest([
            this._authorizationService.organizationId$,
            this._authorizationService.allOrganizationsPermissions$,
        ]).pipe(
            switchMap(([organizationId, authorizationPermission]) =>
                this._dataAccessSharedApiService
                    .getStructure(organizationId)
                    .pipe(
                        map(allItems => {
                            return [
                                ...allItems,
                                ...(authorizationPermission.find(
                                    item => item.product === "Product.Gli",
                                )
                                    ? [
                                          {
                                              name: "Gli",
                                              tenant: "Rivm",
                                              applications: [
                                                  {
                                                      name: "Descriptives",
                                                      referenceProfiles: [],
                                                  } as DescriptivesStructure,
                                              ],
                                          },
                                      ]
                                    : []),
                            ];
                        }),
                    )
                    .pipe(shareReplay(1)),
            ),
            this._addMedicineAppBecauseBackendWontDoIt(),
            shareReplay(1),
        );

        this.structureBasedOnPermissions$ = this._getStructureBasedOnPermissions$(
            this.currentOrganizationStructure$,
            this._authorizationService.currentOrganizationPermissions$,
        );

        this.navigation$ = combineLatest([
            this.structureBasedOnPermissions$,
            this._authorizationService.canAccessOverview$,
            this._authorizationService.overviewRegistryPermissions$,
            this._authorizationService.organizationLgId$,
        ]).pipe(
            map(([structure, canAccessOverview, overviewRegistryPermissions, organizationId]) =>
                this._getRootNavNodes(
                    structure,
                    canAccessOverview,
                    overviewRegistryPermissions,
                    organizationId,
                ),
            ),
            startWith([]),
        );

        this.registryNavigation$ = combineLatest([
            this.structureBasedOnPermissions$,
            this._authorizationService.canAccessOverview$,
            this._authorizationService.overviewRegistryPermissions$,
            this._authorizationService.organizationLgId$,
        ]).pipe(
            map(([structure, canAccessOverview, overviewRegistryPermissions, organizationId]) =>
                this._getRootNavNodes(
                    structure,
                    canAccessOverview,
                    overviewRegistryPermissions,
                    organizationId,
                    {
                        includeAppLevel: false,
                        rootPath: "portal",
                    },
                ),
            ),
            startWith([]),
        );
    }

    checkApplicationAvailability(
        structure: CodmanStructure,
        registryId: string,
        applicationName: string,
    ): void {
        const registryApplications =
            structure.find(registry => registry.name === registryId)?.applications ?? [];
        if (!registryApplications.find(application => application.name === applicationName)) {
            combineLatest([
                this._sharedConfigService.configuration$,
                this._authorizationService.organizationLgId$,
            ])
                .pipe(first())
                .subscribe(([{ dotEnv, domain, tenant }, organizationId]) => {
                    window.location.href = `https://codman.${tenant}${dotEnv}.${domain}/portal/${registryId}?organizationId=${organizationId}`;
                });
        }
    }

    private _addMedicineAppBecauseBackendWontDoIt(): MonoTypeOperatorFunction<CodmanStructure> {
        return tap(structure =>
            structure.forEach(registry =>
                registry.applications.push({ name: Dashboards.Medicine }),
            ),
        );
    }

    private _getStructureBasedOnPermissions$(
        structure$: Observable<CodmanStructure>,
        permissions$: Observable<AuthorizationExtendedPermission[]>,
    ): Observable<CodmanStructure> {
        return zip([structure$, permissions$]).pipe(
            map(([structure, permissions]) => {
                const permittedRegistries = this._filterRegistriesBasedOnPermissions(
                    structure,
                    permissions,
                );
                return permittedRegistries
                    .map(registry => {
                        const registryApplications =
                            this._filterRegistryApplicationsBasedOnPermissions(
                                registry,
                                permissions,
                            );
                        return { ...registry, applications: registryApplications };
                    })
                    .sort((a, b) => a.name.localeCompare(b.name));
            }),
        );
    }

    private _filterRegistriesBasedOnPermissions(
        structure: CodmanStructure,
        permissions: AuthorizationExtendedPermission[],
    ): CodmanStructure {
        return structure.filter(registry =>
            permissions.find(
                permission => parseProductCode(permission.product)?.id === registry.name,
            ),
        );
    }

    private _filterRegistryApplicationsBasedOnPermissions(
        registry: RegistryStructure,
        permissions: AuthorizationExtendedPermission[],
    ): ApplicationStructure[] {
        return registry.applications.filter(app =>
            permissions.find(
                permission =>
                    parseProductCode(permission.product)?.id === registry.name &&
                    permission.applicationInstance === this._getAppNameToInstance(app),
            ),
        );
    }

    private _getAppNameToInstance(app: ApplicationStructure): string {
        return this._sharedConfigService.getCountry() === "CA"
            ? APPNAME_TO_APPINSTANCE_CA_LOOKUP[app.name]
            : APPNAME_TO_APPINSTANCE_LOOKUP[app.name];
    }

    getNavigation$(): Observable<INavNode[]> {
        return this.navigation$;
    }

    private _getRootNavNodes(
        structure: CodmanStructure,
        canAccessOverview: boolean,
        overviewRegistryPermissions: string[],
        organizationId: number,
        config: { includeAppLevel: boolean; rootPath: string } = {
            includeAppLevel: true,
            rootPath: "",
        },
    ): INavNode[] {
        const queryParams = [{ name: organizationIdUrlParam, value: organizationId.toString() }];
        const dotTenant = this._tenant ? `.${this._tenant}` : "";
        const domain =
            TENANT_DOMAINS.find(tenant => tenant.tenant.includes(this._tenant ?? ""))?.domain ??
            DEFAULT_DOMAIN;

        return [
            {
                id: "root",
                path: config.rootPath,
                noBreadcrumb: true,
                children: [
                    ...(canAccessOverview
                        ? [
                              {
                                  id: "overview",
                                  path:
                                      this._currentApp === "overview" && this._tenant === "dica"
                                          ? "dashboard"
                                          : `https://codman-overview.dica${this._dotEnv}.${DEFAULT_DOMAIN}/dashboard`,
                                  queryParams,
                                  lid: "APP._Shared.Applications.Overview.Name",
                                  inNewTab: true,
                                  anonymous: false,
                              },
                          ]
                        : []),
                    ...[...structure]
                        .filter(registry =>
                            config.includeAppLevel
                                ? this._hasAllowedApp(registry, ALLOWED_APPS_WITH_OVERVIEW)
                                : this._hasAllowedApp(registry, ALLOWED_APPS),
                        )
                        .map(registry =>
                            this._getRegistryNavigation(
                                registry,
                                config,
                                overviewRegistryPermissions,
                                queryParams,
                            ),
                        ),
                    {
                        id: "portalExt",
                        path: `https://codman${dotTenant}${this._dotEnv}.${domain}/`,
                        queryParams,
                        lid: "Codman",
                        inNewTab: true,
                        hidden: true,
                    },
                ],
            },
        ];
    }

    private _getRegistryNavigation(
        registry: RegistryStructure,
        config: { includeAppLevel: boolean },
        overviewRegistryPermissions: string[],
        queryParams: INavNodeParameter[],
    ): INavNode {
        const isCurrentTenant = registry.tenant.toLowerCase() === this._tenant;
        const registryApps: INavNode[] = [];
        const registryTenant = <RegistryTenant>registry.tenant.toLowerCase();
        const domain =
            TENANT_DOMAINS.find(tenant => tenant.tenant.includes(registryTenant))?.domain ??
            DEFAULT_DOMAIN;
        const portalRegistryUrl = `https://codman.${registryTenant}${this._dotEnv}.${domain}/portal/${registry.name}`;

        if (config.includeAppLevel) {
            if (this._hasAllowedApp(registry, ALLOWED_APPS)) {
                registryApps.push({
                    lid: "APP._Shared.Applications.Portal.Name",
                    path: portalRegistryUrl,
                    queryParams,
                });
                if (
                    overviewRegistryPermissions.find(permission => permission === registry.name) &&
                    registry.applications.find(app => app.name === "Overview")
                ) {
                    registryApps.push({
                        lid: "APP._Shared.Applications.Overview.Name",
                        path: `https://codman-overview.${registryTenant}${this._dotEnv}.${domain}/dashboard/${registry.name}`,
                        queryParams,
                        data: { appName: "overview" },
                    });
                }
                registryApps.push(
                    ...this._getAppNavigation(
                        registry.applications,
                        registry.name,
                        registryTenant,
                        isCurrentTenant,
                        queryParams,
                    ),
                );
            }
        }

        return {
            id: registry.name,
            lid: `Registries.${registry.name}.Abbreviation`,
            path: isCurrentTenant ? registry.name : portalRegistryUrl,
            queryParams,
            children: registryApps,
        };
    }

    private _hasAllowedApp(registry: RegistryStructure, allowedApps: string[]): boolean {
        return registry.applications.some(app => allowedApps.includes(app.name));
    }

    private _getAppNavigation(
        applications: ApplicationStructure[],
        registryId: string,
        registryTenant: RegistryTenant,
        isCurrentTenant: boolean,
        queryParams: INavNodeParameter[],
    ): INavNode[] {
        return applications
            .map(application => {
                let path = "";
                let isCurrentApp = false;
                let useLocalNavigation = false;
                let noNodeHasMultipleChildren = false;
                const domain =
                    TENANT_DOMAINS.find(tenant => tenant.tenant.includes(registryTenant))?.domain ??
                    DEFAULT_DOMAIN;
                switch (application.name) {
                    case Dashboards.Descriptives:
                        const defaultReferenceProfile = application.referenceProfiles[0];
                        isCurrentApp = this._currentApp === "descriptives";
                        useLocalNavigation = isCurrentApp && isCurrentTenant;
                        noNodeHasMultipleChildren =
                            application.referenceProfiles.length <= 1 &&
                            defaultReferenceProfile?.subsets.length <= 1;
                        if (!useLocalNavigation) {
                            path = `https://codman-descriptives.${registryTenant}${this._dotEnv}.${domain}/${registryId}`;
                        }
                        if (registryId === "Gli") {
                            path = `https://gli-descriptives${this._dashEnv}.valuebase.nl`;
                        }
                        if (noNodeHasMultipleChildren && this._currentApp !== "portal") {
                            if (!useLocalNavigation) {
                                path = path.concat("/");
                            }
                            path = path.concat(
                                `${defaultReferenceProfile?.id}/subset/${defaultReferenceProfile?.subsets[0]?.id}`,
                            );
                        }
                        return <INavNode>{
                            lid: "APP._Shared.Applications.Descriptives.Name",
                            path: `${path}`,
                            queryParams,
                            data: { appName: "descriptives" },
                            children: this._getDescriptivesNavigation(
                                application,
                                useLocalNavigation ? "" : path + "/",
                                useLocalNavigation,
                                queryParams,
                            ),
                        };
                    case Dashboards.Indicators:
                        isCurrentApp = this._currentApp === "indicators";
                        useLocalNavigation = isCurrentApp && isCurrentTenant;
                        noNodeHasMultipleChildren = application.pages.length <= 1;
                        if (!useLocalNavigation) {
                            path = `https://codman-indicators.${registryTenant}${this._dotEnv}.${domain}/${registryId}`;
                        }
                        if (noNodeHasMultipleChildren && this._currentApp !== "portal") {
                            if (!useLocalNavigation) {
                                path = path.concat("/");
                            }
                            path = path.concat(`${application.pages[0]}`);
                        }
                        return <INavNode>{
                            lid: "APP._Shared.Applications.Indicators.Name",
                            path,
                            queryParams,
                            data: { appName: "indicators" },
                            children: this._getIndicatorsNavigation(
                                application,
                                useLocalNavigation ? "" : path + "/",
                                queryParams,
                            ),
                        };
                    case Dashboards.Exploration:
                        const defaultSubset = application.subsets[0];
                        isCurrentApp = this._currentApp === "exploration";
                        useLocalNavigation = isCurrentApp && isCurrentTenant;
                        noNodeHasMultipleChildren =
                            application.subsets.length <= 1 && defaultSubset?.pages.length <= 1;
                        if (!useLocalNavigation) {
                            path = `https://codman-exploration.${registryTenant}${this._dotEnv}.${domain}/${registryId}`;
                        }
                        if (noNodeHasMultipleChildren && this._currentApp !== "portal") {
                            if (!useLocalNavigation) {
                                path = path.concat("/");
                            }
                            path = path.concat(
                                `subsets/${defaultSubset.id}/${defaultSubset.pages[0].id}`,
                            );
                        }
                        return <INavNode>{
                            lid: "APP._Shared.Applications.Exploration.Name",
                            path,
                            queryParams,
                            data: { appName: "exploration" },
                            children: this._getExplorationNavigation(
                                application,
                                registryId,
                                useLocalNavigation ? "" : path + "/",
                                useLocalNavigation,
                                queryParams,
                                registryTenant,
                            ),
                        };
                    case Dashboards.PatientsLikeMe:
                        isCurrentApp = this._currentApp === "patients";
                        useLocalNavigation = isCurrentApp && isCurrentTenant;
                        if (!useLocalNavigation) {
                            path = `https://codman-patients.${registryTenant}${this._dotEnv}.${domain}`;
                        }
                        return <INavNode>{
                            lid: "APP._Shared.Applications.Patients.Name",
                            path,
                            queryParams,
                            data: { appName: "patients" },
                            children: this._getPatientsNavigation(
                                application,
                                useLocalNavigation ? "" : path + "/",
                                queryParams,
                            ),
                        };
                    case Dashboards.Medicine:
                        return <INavNode>{
                            lid: "APP._Shared.Applications.Medicine.Name",
                            path: "https://mva.mrdm.nl.logex.com/",
                            data: { appName: "medicine" },
                        };
                    default:
                        return null;
                }
            })
            .filter((navNode): navNode is INavNode => navNode != null);
    }

    private _getDescriptivesNavigation(
        structure: DescriptivesStructure,
        currentPath: string,
        useLocalNavigation: boolean,
        queryParams: INavNodeParameter[],
    ): INavNode[] {
        return structure.referenceProfiles.map(referenceProfile => {
            let path = currentPath.concat(`${referenceProfile.id}`);
            if (referenceProfile.subsets.length <= 1) {
                path = path.concat(`/subset/${referenceProfile.subsets[0]?.id}`);
            }
            return {
                lid: `APP._Navigation.Descriptives.ReferenceProfile.${
                    referenceProfile.lid || referenceProfile.id
                }`,
                path: `${path}`,
                disabled:
                    structure.referenceProfiles.length <= 1 &&
                    structure.referenceProfiles[0]?.subsets.length <= 1,
                children: this._getDescriptivesSubsetsNavigation(
                    referenceProfile.subsets,
                    useLocalNavigation ? "" : path + "/",
                    queryParams,
                ),
            };
        });
    }

    private _getDescriptivesSubsetsNavigation(
        subsets: DescriptivesSubset[],
        currentPath: string,
        queryParams: INavNodeParameter[],
    ): INavNode[] {
        return subsets.map(subset => {
            const path = currentPath.concat(`subset/${subset.id}`);
            return {
                lid: subset.label,
                path,
                queryParams,
                disabled: subsets.length <= 1,
            };
        });
    }

    private _getIndicatorsNavigation(
        structure: IndicatorsStructure,
        currentPath: string,
        queryParams: INavNodeParameter[],
    ): INavNode[] {
        return structure.pages.map(pageId => {
            const path = currentPath.concat(`${pageId}`);
            return {
                lid: `APP._Navigation.Indicators.Pages.${pageId}`,
                path,
                queryParams,
                disabled: structure.pages.length <= 1,
            };
        });
    }

    private _getExplorationNavigation(
        structure: ExplorationStructure,
        registryId: string,
        currentPath: string,
        useLocalNavigation: boolean,
        queryParams: INavNodeParameter[],
        registryTenant: RegistryTenant,
    ): INavNode[] {
        if (registryTenant !== this._tenant) return [];
        if (structure.subsets != null) {
            return structure.subsets.map(subset => {
                let path = currentPath.concat(`subsets/${subset.id}`);
                if (subset.pages.length <= 1) {
                    path = path.concat(`/${subset.pages[0]?.id}`);
                }
                return {
                    lid: `Registries.${registryId}.Subsets.${subset.id}.Label`,
                    path: `${path}`,
                    disabled:
                        structure.subsets.length <= 1 && structure.subsets[0]?.pages.length <= 1,
                    children: this._getExplorationPages(
                        subset,
                        registryId,
                        useLocalNavigation ? "" : path + "/",
                        queryParams,
                        registryTenant,
                    ),
                };
            });
        }
        // TODO: finish for subsetless registries
        // return this._getExplorationPages(structure, registryId);
        return [];
    }

    private _getExplorationPages(
        subset: ExplorationSubset,
        registryId: string,
        currentPath: string,
        queryParams: INavNodeParameter[],
        registryTenant: RegistryTenant,
    ): INavNode[] {
        if (registryTenant !== this._tenant) return [];
        return subset.pages.map(page => {
            const path = currentPath.concat(`${page.id}`);
            return {
                lid: `Registries.${registryId}.Subsets.${subset.id}.Pages.${page.id}.Label`,
                path,
                queryParams,
                disabled: subset.pages.length <= 1,
            };
        });
    }

    private _getPatientsNavigation(
        structure: PatientsStructure,
        currentPath: string,
        queryParams: INavNodeParameter[],
    ): INavNode[] {
        return structure.subsets.map(subset => {
            const path = currentPath.concat(`${subset.id}`);
            return {
                lid: `APP._Navigation.Patients.Subsets.${subset.id}`,
                path,
                queryParams,
            };
        });
    }
}
